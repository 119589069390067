/* eslint-disable no-param-reassign */
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import {get, find, pick} from 'lodash';
import {toast} from '../../utils/toast';
import Api from '../../services/api';
import {hasTokensStored, parseFirstError} from '../../helpers/auth';

import {
  unauthorizedStatusCode,
  unprocessableEntityStatusCode,
} from '../../constants/responseCodes';
import {FEATURES} from '../../constants/features';

export const setLanguage = createAction('SETTINGS/SET_LANGUAGE');
export const setProfile = createAction('SETTINGS/SET_PROFILE');
export const toggleFetching = createAction('SETTINGS/TOGGLE_FETCHING');
export const toggleAppBooting = createAction('SETTINGS/TOGGLE_APP_BOOTING');
export const updateProfile = createAction('SETTINGS/UPDATE_PROFILE');
export const setAfterLogin = createAction('SETTINGS/SET_AFTER_LOGIN');

export const initialState = {
  appBooting: hasTokensStored(),
  language: 'en',
  isAfterLogin: false,
  profile: {
    data: {},
    isLoading: false,
  },
};

export default createReducer(initialState, {
  [setLanguage]: (state, action) => {
    state.language = action.payload;
  },
  [setProfile]: (state, action) => {
    state.profile.data = action.payload;
  },
  [updateProfile]: (state, action) => {
    state.profile.data = {...state.profile.data, ...action.payload};
  },
  [toggleFetching]: (state, action) => {
    state[action.payload.key].isLoading = action.payload.toggle;
  },
  [toggleAppBooting]: (state, action) => {
    state.appBooting = action.payload;
  },
  [setAfterLogin]: (state, action) => {
    state.isAfterLogin = action.payload;
  },
  'AUTH/LOGOUT': (state) => {
    state.appBooting = false;
  },
});

/* ACTION CREATORS */
export const fetchUserProfile = () => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'profile', toggle: true}));

    /* Fetch user profile data */
    const {
      data: {data},
    } = await Api.user.getProfile();

    /* Store user profile in store */
    dispatch(setProfile(data));

    return data;
  } catch (err) {
    const status = err.response?.status; // Safely access err.response.status

    // If we caught a 401 don't show any errors, since we'll redirect to auth/signup
    // If we caught a 422 don't show any errors, since we'll redirect to auth/verify_email

    if (![unauthorizedStatusCode, unprocessableEntityStatusCode].includes(status)) {
      toast.error('There was an error trying to fetch profile');
    }
    throw err;
  } finally {
    dispatch(toggleFetching({key: 'profile', toggle: false}));
  }
};

// redeemCode
export const redeemCode = (props) => async (dispatch) => {
  try {
    const params = pick(props, ['code']);

    const response = await Api.appsumo.redeemCode(params);

    toast.success(response.data.message || 'AppSumo code redeemed.');
  } catch (err) {
    const error = parseFirstError(err, 'There was an error trying to redeem code');

    if (err.response.status === unauthorizedStatusCode) {
      toast.error(err.response.data.message);
    } else {
      toast.error(error);
    }
  } finally {
    dispatch(fetchUserProfile());
  }
};

export const changePassword = (props) => async (dispatch) => {
  try {
    const params = pick(props, ['current', 'password', 'password2']);

    /* Change current users password */
    await Api.user.changePassword(params);

    /* Store user profile in store */
    dispatch(updateProfile(params));

    toast.success('Password changed!');
  } catch (err) {
    const error = parseFirstError(err, 'There was an error trying to update your password');

    toast.error(error);
  }
};

export const apiRevoke = () => async (dispatch) => {
  try {
    await Api.user.revokeApi();

    toast.success('API key revoked!');
  } catch (err) {
    const error = parseFirstError(err, 'There was an error trying to revoke API key');

    toast.error(error);
  } finally {
    dispatch(fetchUserProfile());
  }
};

export const apiCreate = () => async (dispatch) => {
  try {
    await Api.user.createApi();

    toast.success('API key created!');
  } catch (err) {
    const error = parseFirstError(err, 'There was an error trying to create API key');

    toast.error(error);
  } finally {
    dispatch(fetchUserProfile());
  }
};

export const changeProfile = (props) => async (dispatch) => {
  try {
    const params = pick(props, ['full_name', 'first_name', 'last_name', 'email', 'phone', 'company_name', 'role', 'goal']);

    /* Change current users password */
    await Api.user.updateProfile(params);

    /* Store user profile in store */
    dispatch(updateProfile(params));

    toast.success('Profile Updated!');
  } catch (err) {
    const error = parseFirstError(err, 'There was an error trying to update your profile');

    toast.error(error);
  }
};

export const updateCompanySettings = ({companyId, data}) => async (dispatch) => {
  try {
    await Api.company.updateCompany({companyId, data});

    /* Store user profile in store */
    dispatch(fetchUserProfile());

    toast.success('Company settings updated!');
  } catch (err) {
    const error = parseFirstError(err, 'There was an error trying to update company settings');

    toast.error(error);
  }
};

/* SELECTORS */
const _getProfileSelector = (state) => state?.settings?.profile?.data;

export const getProfileSelector = createSelector(_getProfileSelector, (profile = {}) => ({
  id: profile.id,
  full_name: profile.full_name,
  first_name: profile.first_name,
  last_name: profile.last_name,
  email: profile.email,
  created_at: profile.created_at,
  subscription: profile.subscription,
  companyId: profile.companies ? profile.companies.meta.currentCompany.id : undefined,
  company_name: profile.company_name,
  phone: profile.phone,
  goal: profile.goal,
  meta: profile.companies ? profile.companies.meta : undefined,
  is_manager: profile.is_manager,
  role: profile.role,
  is_goal: profile.is_goal,
  apiKey: profile.api,
}));

export const getProfileSubscription = createSelector(
  _getProfileSelector,
  (profile = {}) => profile.subscription,
);

export const getProfileSubscriptionFeatures = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.plan.features', [])
);

export const getProfileSubscriptionFeaturesCodes = createSelector(
  _getProfileSelector,
  (profile = {}) => {
    const features = get(profile, 'subscription.plan.features', []);

    return features.map((feature) => feature.code);
  }
);

export const getProfileSubscriptionPlanName = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.plan.name', '')
);

export const getProfileSubscriptionPlanType = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.plan.type', '')
);

export const isDefaultOrFreeTrial = createSelector(
  getProfileSubscriptionPlanType,
  (planType) => planType === 'default' || planType === 'free',
);

export const getProfileSubscriptionCreationDate = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.created_at', '')
);

export const getProfileSubscriptionStripeStatus = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.stripe_status', '')
);

export const getProfileSubscriptionFailedReason = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.last_failure_message', '')
);

export const getProfileSubscriptionFailedDate = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.last_failed_at', '')
);

export const getProfileSubscriptionResetDate = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.resets_at', '')
);

export const getProfileSubscriptionNextCharge = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.charges_at', '')
);

export const getProfileSubscriptionCreated = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.created_at', null)
);

export const getProfileSubscriptionEnd = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.ends_at', null)
);

export const getProfileRights = createSelector(
  _getProfileSelector,
  (profile = {}) => get(profile, 'subscription.actions', {
    cancel: false,
    swap: false,
    manage: false,
  })
);

function selectRemainingDesign (profile) {
  const leftSelectedDesigns = 50000;

  let leftDesigns = get(
    find(get(profile, 'subscription.plan.features', []), {code: 'designs'}),
    'remaining',
    0
  );

  if (leftDesigns === null) {
    leftDesigns = leftSelectedDesigns;
  }

  return leftDesigns;
}

export const getRemainingDesignsSelector = createSelector(_getProfileSelector, (profile = {}) => {
  const remaining = get(
    find(get(profile, 'subscription.plan.features', []), {code: 'designs'}),
    'remaining',
    0
  );

  return remaining === null ? 'unlimited' : remaining || 0;
});

export const hasCredits = createSelector(_getProfileSelector, (profile = {}) => {
  const remaining = get(
    find(get(profile, 'subscription.plan.features', []), {code: 'designs'}),
    'remaining',
    0
  );

  return remaining === null || remaining > 0;
});

export const hasGenerativeFeature = createSelector(
  _getProfileSelector,
  (profile = {}) => {
    const features = get(profile, 'subscription.plan.features', []);

    const generative = find(features, {code: FEATURES.GENERATIVE_RECOMMENDATIONS});

    return !!generative;
  }
);

export const hasVideoFeature = createSelector(
  _getProfileSelector,
  (profile = {}) => {
    const features = get(profile, 'subscription.plan.features', []);

    const video = find(features, {code: FEATURES.VIDEO_CREDITS});

    return !!video;
  }
);

export const hasAnyReportsFeature = createSelector(
  _getProfileSelector,
  (profile = {}) => {
    const features = get(profile, 'subscription.plan.features', []);

    const pdf_report = find(features, {code: FEATURES.PDF_REPORT});

    const ppt_report = find(features, {code: FEATURES.PPT_REPORT});

    return !!pdf_report || !!ppt_report;
  }
);

export const hasApiFeature = createSelector(
  _getProfileSelector,
  (profile = {}) => {
    const features = get(profile, 'subscription.plan.features', []);

    const api = find(features, {code: FEATURES.API});

    return !!api;
  }
);

export const getStorageTimeFeature = createSelector(
  _getProfileSelector,
  (profile = {}) => {
    const limit = get(
      find(get(profile, 'subscription.plan.features', []), {code: FEATURES.STORAGE_TIME}),
      'limit',
      null
    );

    return limit;
  }
);

export const getRemainingVideoSecondsSelector = createSelector(_getProfileSelector, (profile = {}) => {
  /* video_credits calculation migrated to designs (credits) system */

  const limit = get(
    find(get(profile, 'subscription.plan.features', []), {code: 'video_credits'}),
    'remaining',
    0
  );

  let remaining = 0;

  if (limit && limit > 0) {
    remaining = get(
      find(get(profile, 'subscription.plan.features', []), {code: 'video_credits'}),
      'remaining',
      0
    );
  } else {
    remaining = get(
      find(get(profile, 'subscription.plan.features', []), {code: 'designs'}),
      'remaining',
      0
    );
  }

  return remaining === null ? 'unlimited' : remaining || 0;
});

export const getLimitDesignsSelector = createSelector(_getProfileSelector, (profile = {}) => {
  const limit = get(
    find(get(profile, 'subscription.plan.features', []), {code: 'designs'}),
    'limit',
    0
  );

  return limit === null ? 'unlimited' : limit || 0;
});

export const getIntercomProfileSelector = createSelector(_getProfileSelector, (profile = {}) => ({
  user_id: get(profile, 'id', ''),
  email: get(profile, 'email', ''),
  first_name: get(profile, 'first_name', ''),
  last_name: get(profile, 'last_name', ''),
  created_at: get(profile, 'created_at', ''),
  phone: get(profile, 'phone', ''),
  company_name: get(profile, 'company_name', ''),
  user_role: get(profile, 'role', ''),
  goal: get(profile, 'goal', ''),
  type: get(profile, 'companies.meta.currentCompany.type', ''),
  industry: get(profile, 'companies.meta.currentCompany.industry', ''),
  agency: get(profile, 'companies.meta.currentCompany.agency', ''),
  subscription_name: get(profile, 'subscription.plan.name', ''),
  subscription_created_at: get(profile, 'subscription.created_at', ''),
  subscription_ends_at: get(profile, 'subscription.ends_at', ''),
  credits_remaining: selectRemainingDesign(profile),
}));

export const getSelectedCompany = createSelector(_getProfileSelector, (profile = {}) => {
  return profile?.companies?.meta?.currentCompany;
});

export const getIsAfterLogin = (state) => get(state, 'settings.isAfterLogin', false);

export const getActionsSelector = createSelector(
  _getProfileSelector,
  (profile = {}) => profile.actions
);
